<template>
  <div class="container">
    <header-back name='话费充值'></header-back>
    <section class="top44">
      <div class="rec-main">
        <div class="rec-tit">请输入手机号码</div>
        <div class="rec-m">
          <div class="rec-inpt">
            <van-field
              v-model="phone"
              clearable
              placeholder="请输入"
              :border="false"
            />
          </div>
          <div class="rec-icon">
            <van-icon
              name="user-circle-o"
              color='#fff'
              size='30'
            />
          </div>
        </div>
        <div class="rec-cen">

          <div
            class="rec-list"
            v-for="(item,index) in recList"
            :key='index'
            @click="onRecClick(item)"
          ><span>{{item}}</span></div>

        </div>

      </div>
      <div class="rec-f">
        <van-cell value="">
          <div
            class="rec-f-c"
            slot="title"
          >
            <div class="rec-f-img">
              <van-image
                width="20"
                height="20"
                :src="require('../../assets/image/home/rec.png')"
              />

            </div>
            <div class="van-cell-text">充值记录</div>
          </div>
        </van-cell>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";

export default {
  components: { HeaderBack },
  data() {
    return {
      phone: "13123455432",
      recList: [
        "5元",
        "10元",
        "20元",
        "30元",
        "50元",
        "100元",
        "200元",
        "300元",
        "500元",
      ],
      recIndex: "",
    };
  },

  created() {},

  methods: {
    onRecClick(item) {},
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 16px 15px;
  box-sizing: border-box;
}
.rec-main {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  min-height: 110px;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, #ff0661 100%);
}
.rec-tit {
  font-size: 14px;
  color: #fff;
  padding-left: 12px;
}
.rec-m {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
}
.rec-inpt {
  flex: 1;
}
.rec-icon {
  width: 60rpx;
}
.rec-inpt .van-cell {
  background-color: transparent;
  color: #fff;
}
.rec-cen {
  background-color: #fff;
  border-radius: 6px;
  margin-top: 15px;
  padding: 12px 0 12px 6px;
}

.rec-list {
  display: inline-block;
  width: 30%;
  margin: 5px;
  background-color: #ffe6f3;
  border-radius: 6px;
  text-align: center;
  padding: 20px 0;
  font-size: 20px;
}

.rec-list > span {
  font-weight: 600;
  color: $textColor;
}
.rec-f {
  width: 100%;
  padding: 12px 0;
  box-sizing: border-box;
}
.rec-f-c {
  display: flex;
}
.rec-f-img {
  width: 22px;
  height: 22px;
  margin-right: 16px;
}
.rec-f-img image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
</style>
<style>
.rec-inpt .van-cell .van-field__control {
  color: #fff !important;
  font-size: 24px;
}
</style>
